<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-skeleton type="input" />
      </b-col>
      <b-col md="6">
        <b-skeleton type="input" />
      </b-col>
    </b-row>
    <b-row class="pt-2">
      <b-col md="6">
        <b-skeleton type="input" />
      </b-col>
      <b-col md="6">
        <b-skeleton type="input" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BSkeleton } from 'bootstrap-vue'

export default {
  name: 'Skeleton',
  components: {
    BRow,
    BCol,
    BSkeleton,
  },
}
</script>

<style scoped>

</style>
