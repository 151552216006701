import { TEXT_INPUT } from '@/views/components/DynamicForm/constants'

export default function config() {
  const fields = {
    keycloak_host: {
      type: TEXT_INPUT,
      label: 'KeyCloak Host',
      rules: 'required',
      placeholder: 'Type...',
    },
    keycloak_realm: {
      type: TEXT_INPUT,
      label: 'KeyCloak Realm',
      rules: 'required',
      placeholder: 'Type...',
    },
    keycloak_client_id: {
      type: TEXT_INPUT,
      label: 'KeyCloak Client ID',
      rules: 'required',
      placeholder: 'Type...',
    },
    keycloak_client_secret: {
      type: TEXT_INPUT,
      label: 'KeyCloak Client Secret',
      rules: 'required',
      placeholder: 'Type...',
    },
  }
  return {
    fields,
  }
}
