import IntegrationForm from '@/views/settings/general/integrations/view/component/IntegrationForm.vue'

export default {
  components: {
    IntegrationForm,
  },
  data() {
    return {
      loading: true,
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs.formRef) {
      this.$refs.formRef.showConfirmationModal(next)
    } else {
      next()
    }
  },
}
